import * as React from "react"


import {  Button, P, H1} from "@dnb/eufemia";
import { Img } from "@dnb/eufemia/elements";
import eth from "../images/eth.png";
import "@dnb/eufemia/style";
import { navigate } from "gatsby";
import { CustomButton } from "../components/styledComponents";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import Login from "../views/Login";

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5rem;
`;

const ImgStyled = styled(Img)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
`

const DivStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const IndexPage = () => ( 
  <Login></Login>
) 

/* const IndexPage = () => ( 
    <ContainerStyled>
      <ImgStyled
        src={eth}  alt="eth" height="50%" width="50%"
      />
      <DivStyled>
        <h1>Get that coin.</h1>
        <P>Here at DNB we value our customers wish to get rich.</P>
        <P>Lets get rich together.</P>
        <P>Register below to enter the portal of wealth and prosperity</P>
        <CustomButton right="1rem" onClick={() => navigate("/page-2/")}>Login</CustomButton>
        <CustomButton onClick={() => navigate("/page-2/")}>Register now</CustomButton>
      </DivStyled>
    </ContainerStyled>
)  */

export default IndexPage
