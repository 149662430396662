import { Anchor, Button, H1, H2, Input, Section } from "@dnb/eufemia"
import React, { useEffect } from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { useState } from "react"
import { navigate } from "gatsby"

const Login = () => {
  const [ssn, setSSN] = useState<string>("")

  const login = () => {
    if (ssn) {

      let user_id = "no_user";
      switch(ssn) {
        case "123": {
          user_id = '25b04dae-6fbb-4a74-b9ce-db10dc80c54f';
          break;
        }
      }

      localStorage.setItem("access_token", user_id);
      navigate("/marketplace")
    }
  }

  return (
    <Layout>
      <MainTitle top="5rem" bottom="5rem">
        Funny money
      </MainTitle>
      <SectionStyled style_type="lavender">
        <Container>
          <LoginContainer>
            <LoginInfo>
              <H2 bottom="1rem">Logg inn</H2>
              <div>Vennligst logg inn med ditt fødsels- og personnummer. </div>
              <Anchor top="0.5rem">
                Svar på vanlige spørsmål om innlogging i nettbanken
              </Anchor>
            </LoginInfo>
            <LoginInputContainer>
              <LoginInput>
                <div>Fødsels- og personnummer</div>
                <Input
                  on_change={({ value }: { value: string }) => setSSN(value)}
                  top="0.5rem"
                  placeholder="11 siffer"
                ></Input>
              </LoginInput>
              <LoginButton>
                <Button disabled={!ssn} onClick={login}>Neste</Button>
              </LoginButton>
            </LoginInputContainer>
          </LoginContainer>
        </Container>
      </SectionStyled>
    </Layout>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const LoginButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1.5rem;
`

const LoginInputContainer = styled.div`
  background-color: var(--color-sand-yellow);
  width: 55%;
  border-radius: 0 0.5rem 0.5rem 0;
`

const LoginInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 7rem;
  padding-bottom: 5rem;
`

const LoginInfo = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.5rem 0 0 0.5rem;

  width: 45%;
  padding: 2rem 2rem;
  padding-bottom: 6rem;
`

const LoginContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1.5rem 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`

const SectionStyled = styled(Section)`
  padding-bottom: 100rem;
`
const MainTitle = styled(H1)`
  display: flex;
  justify-content: center;
  color: var(--color-ocean-green);
`

export default Login
